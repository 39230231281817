.headerDiv {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  background: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.nameTag {
  color: white;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 18rem;
  margin: 1rem;
}

.nameTag:hover {
  cursor: pointer;
}

.imageHeader {
  background: url("../assets/Likhan_Image_Thumb.jpg") center/cover no-repeat
    border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.nameTag h1 {
  font-size: 1.25rem;
  font-weight: 200;
  margin-right: 5rem;
}

.toggleButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  margin-right: 2rem;
}

.toggleButton:hover,
.toggleButton:focus {
  animation: getclose 1s ease-in-out forwards;
}

.toggleButton span {
  font-family: "Poppins", sans-serif;
  color: #fff;
  display: inline-block;
  margin: 0.29rem auto;
  font-size: 1.25rem;
  margin: 0.3rem;
}

#arrow {
  margin-bottom: 0;
}

#arrow:hover,
#arror:focus {
  animation: rotatearrow 1s ease-in-out forwards;
}

@media (max-width: 48rem) {
  .headerDiv {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .toggleButton {
    margin-left: 1rem;
    margin-right: 0;
  }
  .toggleButton span {
    font-size: 1rem;
  }

  .nameTag {
    color: white;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    width: auto;
    margin-right: 1rem;
  }
  .nameTag h1 {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 200;
    margin: 0;
    margin-right: 1rem;
    margin-left: 0.5rem;
    padding: 0;
  }

  .imageHeader {
    width: 30px;
    height: 30px;
  }
}

@keyframes getclose {
  0% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotatearrow {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: scale(45deg);
  }
}
