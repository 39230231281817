.about {
  color: #ffffff99;
  font-weight: bold;
  text-align: right;
  width: 100%;
  height: 50rem;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}
.about h1 {
  position: absolute;
  top: -10rem;
  right: 1rem;
  opacity: 0.1;
  font-family: "Poppins", sans-serif;
  font-size: 11rem;
  z-index: 0;
}

.details {
  width: 30rem;
  height: 45rem;
  text-align: left;
}

.details h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top: 3rem;
  margin-right: 7rem;
  margin-bottom: 0;
}

.details h3 {
  text-align: left;
  color: #8c8c8c;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.details a {
  text-decoration: none;
  color: #2f89fc;
  font-size: 1.2rem;
  line-height: 1.8;
  font-weight: 400;
}

.details h5 {
  line-height: 1.5;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.details h4 {
  line-height: 1.5;
  color: #8c8c8c;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
}

.details p {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #2f89fc;
  font-size: 1.2rem;
  line-height: 1.8;
  font-weight: 400;
}

.image {
  width: 23rem;
  height: 36rem;
  background: url("../assets/Likhan_Image_Main.jpg") center/cover no-repeat
      border-box,
    #ffffff;
  border-radius: 15px;
}

@media (max-width: 86rem) {
  .about {
    width: 100%;
  }

  .details {
    position: static;
    width: 45%;
    height: 90%;
  }
  .image {
    width: 18rem;
    height: 26rem;
  }
}

@media (max-width: 48rem) {
  .about h1 {
    position: absolute;
    top: -5rem;
    right: 0;
    font-size: 6rem;
  }

  .detailsContainer {
    position: static;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .details {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .details h2 {
    font-size: 1.8rem;
    text-align: center;
    letter-spacing: 1px;
    width: 100%;
  }

  .details h3 {
    padding: 0;
    margin: 0 auto;
    margin-top: 2rem;
    font-size: 1rem;
    width: 20rem;
    height: fit-content;
    text-align: center;
  }

  .details a {
    margin: 0 auto;
  }

  .details ul {
    margin-left: 1rem;
  }

  .details h5 {
    margin-left: 1rem;
  }

  .details h4 {
    margin-left: 1rem;
  }

  .image {
    display: none;
  }
}
