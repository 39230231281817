.card-container {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  border: 1px solid rgba(138, 134, 134, 0.125);
  border-radius: 10%;
  width: 20rem;
  height: 20rem;
  margin: auto;
  margin-bottom: 1rem;
  overflow: hidden;
}

.card-container:hover,
.card-container:focus {
  cursor: pointer;
}

.card-image {
  height: 70%;
}

.card-content {
  height: 30%;
  border-top: 0.5px solid rgba(138, 134, 134, 0.125);
}
