.services {
  /* border: 2px solid white; */
  color: #ffffff99;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: right;
  width: 100%;
  height: 35rem;
  position: relative;
  overflow: hidden;
  margin-top: 15rem;
}

.services h1 {
  position: absolute;
  top: -3rem;
  left: 1rem;
  opacity: 0.1;
  font-family: "Poppins", sans-serif;
  font-size: 11rem;
  margin: 0;
}

.skills {
  /* border: 2px solid white; */
  color: #ffffff;
  text-align: center;
}

.skills h3 {
  color: #ffffff99;
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: 4px;
  margin: 0;
}

.skills h2 {
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: 2px;
  margin: 0;
}

.skillicons {
  margin: auto auto;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 40rem;
  padding: 0;
}

.skillicons li {
  /* border: 2px solid white; */
  padding: 0;
  height: 80%;
  width: 10rem;
  margin: auto;
}

.skillicons h2 {
  font-size: 1.26rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.skilliconsimage {
  height: 8rem;
  width: 100%;
  margin: 0;
}

@media (max-width: 48rem) {
  .services {
    width: 100%;
    height: 73rem;
  }
  .services h1 {
    top: -2rem;
    font-size: 6rem;
  }

  .skills {
    margin-top: 2rem;
  }

  .skillicons {
    margin-top: 5rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 80%;
  }

  .skillicons li {
    height: 8rem;
    margin: 0.5rem;
  }

  .skillicons h2 {
    text-align: center;
    margin: 0;
  }

  .skilliconsimage {
    height: 5rem;
  }
}
