.projects {
  color: #ffffff99;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: right;
  width: 100%;
  min-height: 30rem;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-top: 7rem;
  margin-bottom: 3rem;
}

.projects h1 {
  position: absolute;
  top: -3rem;
  right: 1rem;
  opacity: 0.1;
  font-family: "Poppins", sans-serif;
  font-size: 11rem;
  z-index: 0;
  margin: 0;
}

.projectdetails {
  color: #ffffff;
  text-align: center;
  height: 9rem;
}

.projectdetails h3 {
  color: #ffffff99;
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: 4px;
  margin: 0;
}

.projectdetails .cardgroup h3 {
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 1rem;
  margin: 0;
}

.projectdetails .cardgroup h3 {
  letter-spacing: 2px;
  margin: 0;
}

.projectdetails h2 {
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: 2px;
  margin: 0;
}

.cardgroup {
  display: grid;
  margin: auto;
  grid-template-columns: auto auto;
  padding: 1rem;
  margin-top: 2rem;
}

@media (max-width: 48rem) {
  .projects {
    height: 35rem;
    margin-top: 5rem;
  }
  .projects h1 {
    top: -2rem;
    right: 1rem;
    font-size: 6rem;
  }

  .cardgroup {
    display: grid;
    margin: auto;
    grid-template-columns: auto;
    padding: 1rem;
    margin-top: 1rem;
  }
}
