.sliderDiv {
  height: 55rem;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  animation: name-appear 1s ease-out forwards;
}

.sliderDiv h1 {
  font-weight: 700;
  font-size: 3.43rem;
  letter-spacing: 10px;
  margin: 0;
}

.sliderDiv h2 {
  color: #ffffff99;
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: 4px;
  margin: 0;
}

@media (max-width: 48rem) {
  .sliderDiv {
    height: 45rem;
    margin-bottom: 10rem;
  }
  .sliderDiv h1 {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
  }

  .sliderDiv h2 {
    font-size: 1rem;
    font-size: 1.25rem;
  }
}

@keyframes name-appear {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
