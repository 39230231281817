.navModal {
  color: #121212;
  font-family: "Poppins", sans-serif;
  line-height: 1.8;
  text-align: center;
}

.navModal h5 {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
}

.navModal h4 {
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  margin: 0.5rem;
}

.navModal p {
  font-size: 1rem;
  font-weight: 400;
}

.navModal a {
  text-decoration: none;
  color: #121212;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: center;
  color: #2f89fc;
}

.navfooter {
  color: #121212;
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 6rem;
  overflow: hidden;
  margin-top: 3rem;
  text-align: center;
  padding: 0;
}

.navfooter p {
  color: #121212;
  font-family: "Poppins", sans-serif;
  width: 60%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  margin: auto;
  padding: 0;
}

.navfooter ul {
  height: 3rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.navfooter li {
  color: #121212;
  height: 100%;
  width: 6rem;
  padding: 0;
}

.navfooter a {
  text-decoration: none;
  color: #121212;
  height: 100%;
  padding: 0;
}

.skilliconsimage-nav {
  color: #121212;
  height: 2.5rem;
  width: 100%;
  margin: 0;
}

.skilliconsimage-nav :hover {
  color: #808080;
}

.closeIcon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.closeIcon:hover,
.closeIcon:active {
  cursor: pointer;
}

@media (max-width: 48rem) {
  .navfooter {
    height: 10rem;
    margin-top: 4rem;
    width: 100%;
    padding: 0;
  }

  .navfooter p {
    width: 100%;
    margin: auto;
    padding: 0;
  }

  .navfooter ul {
    width: 100%;
    margin: auto;
    padding: 0;
  }
  .navfooter li {
    height: 100%;
    width: 3rem;
    padding: 0;
  }
}
