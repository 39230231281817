@import url("https://fonts.googleapis.com/css2?family=KoHo:wght@200&family=Newsreader:wght@200&family=Poppins:wght@300;700&display=swap");

.appContainer {
  background-color: #121212;
  box-sizing: border-box;
}

.loading {
  background-color: #121212;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.app {
  background-color: transparent;
  width: 80%;
  margin: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.button {
  background: transparent;
  border: 0.5px solid #808080;
  width: 5rem;
  height: 2rem;
  border-radius: 15px;
}

.button:hover,
.button:active {
  background: #808080;
  border: none;
  cursor: pointer;
  outline: none;
  animation: getclose 1s ease-out 1 forwards;
}

.buttonimage {
  color: rgb(85, 73, 73);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(117, 117, 119, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@media (max-width: 48rem) {
  .app {
    background-color: transparent;
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .button {
    margin: auto;
  }
}

@media (max-width: 24rem) {
  .button {
    margin-top: 1rem;
  }
}

@keyframes getclose {
  0% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
