.footer {
  color: #ffffff99;
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 10rem;
  overflow: hidden;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  padding: 0;
}

.footer p {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Poppins", sans-serif;
  width: 60%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  margin: auto;
  padding: 0;
}

.footer ul {
  height: 3rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.footer li {
  color: white;
  height: 100%;
  width: 10rem;
  padding: 0;
}

.footer a {
  text-decoration: none;
  color: white;
  height: 100%;
  padding: 0;
}

.skilliconsimage-footer {
  color: #ffffff;
  height: 2.5rem;
  width: 100%;
  margin: 0;
}

.skilliconsimage-footer:hover {
  color: #808080;
}

.footer div {
  margin-top: 1.5rem;
  letter-spacing: 1px;
  font-weight: 300;
}

@media (max-width: 48rem) {
  .footer {
    height: 10rem;
    width: 100%;
    padding: 0;
  }

  .footer p {
    width: 100%;
    margin: auto;
    padding: 0;
  }

  .footer ul {
    width: 100%;
    margin: auto;
    padding: 0;
  }
  .footer li {
    height: 100%;
    width: 5rem;
    padding: 0;
  }

  .footer div {
    margin-top: 0.5rem;
    letter-spacing: 0px;
    font-weight: 300;
  }
}
